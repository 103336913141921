import React from 'react'
import { useNavigate, useParams } from "react-router-dom";
import "./farmer.css";
function EarnCashback() {
  const { id } = useParams();
  const naviagte = useNavigate();
  
  return (
    <main className="sweep-thnku farmer_landing farmer-welcome">               
    <section className="thnk-page">
        <img src="../images/farmer_bg2.png" className="sweep-thnku-bg"/>
        <div className="thnku-content">
            <img src="../images/logo.png" className="sweep-logo"/>
            <div className="sweep-thnku-text">
                <h1 className="sweep-welcome-heading">WELCOME</h1>
                <h4 className="middle_heading">to the SWEEP POWER Reward Program </h4>
            </div>

            <div className="cashback_btn">
               <a data-bs-toggle="offcanvas" data-bs-target="#scan_success"
               onClick={()=>naviagte(`/scan/${id}`)}>CLICK HERE TO
                  EARN CASHBACK</a>
            </div>

        </div>

    </section>
</main>
  )
}

export default EarnCashback