import React from 'react'
import "./farmer.css"
function FarmerEntryPoint() {
  console.log("process env",process.env)
  return (
  <main className="sweep-thnku farmer_landing">
    <section className="thnk-page">
        <img src="../images/farmer_bg1.png" className="img-fluid sweep-thnku-bg" alt=""/>
        <div className="welcome-content">
            <img src="../images/logo.png" className="img-fluid sweep-logo" alt=""/>
            <div className="sweep-thnku-text">
                <h1 className="big_font">Start Your<br/>SWEEP POWER<br/>Reward Journey <br/>Today</h1>
                <a href={`https://api.whatsapp.com/send?phone=918657875297`}><img src="images/wp.png" className="img-fluid wp_img" alt=""/></a>
                {/* <a href={`https://api.whatsapp.com/send?phone=${process.env.WHATS_APP_NUMBER}`}><img src="images/wp.png" className="img-fluid wp_img" alt=""/></a> */}
            </div>
           

        </div>
    </section>
</main>
  )
}

export default FarmerEntryPoint