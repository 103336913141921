import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import "./farmer.css"

function Awesome() {
  const naviagte = useNavigate();
  const { id } = useParams();

  useEffect(() => {
   setTimeout(()=>{
    naviagte(`/upiDetails/${id}`)
   },3000)
  }, [])
  
  return (
    <main class="sweep-thnku farmer_landing f-awesome">
    <section class="thnk-page">
        <img src="../images/farmer_bg1.png" class="img-fluid sweep-thnku-bg" alt=""/>
        <div class="welcome-content">
            <img src="../images/awesome.png" class="img-fluid sweep-logo" alt=""/>
            <div class="sweep-thnku-text">
               
               <ul>
                   <li>
                      <h4 class="middle_heading">You’ve scanned</h4>
                      <p class="green_middle">3 litres</p>
                   </li>
                   <li>
                       <h4 class="middle_heading">of SWEEP POWER. Just</h4>
                      <p class="green_middle">7 litres</p>
                   </li>
                   <li>
                    <h4 class="middle_heading" >more, and you can</h4>
                      <p class="green_middle">WIN MEGA PRIZES!</p>
                   </li>
               </ul>
                <img src="../images/stars.png" class="img-fluid stars" alt=""/>
            </div>
           

        </div>
    </section>
</main>
  )
}

export default Awesome