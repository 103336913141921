import React, { useEffect } from 'react'
import confetti from 'canvas-confetti';



function Thankyou() {

    useEffect(() => {
        makeItRain()
    }, [])

    const makeItRain = () => {
        const end = Date.now() + (2 * 500);
        const colors = ['#bb0000', '#ffffff'];

        const frame = () => {
            const centerX = window.innerWidth / 2;
            const centerY = window.innerHeight / 2;

            confetti({
                particleCount: 2,
                angle: 60,
                spread: 55,
                origin: { x: centerX / window.innerWidth, y: centerY / window.innerHeight },
                colors: colors
            });
            confetti({
                particleCount: 2,
                angle: 120,
                spread: 55,
                origin: { x: centerX / window.innerWidth, y: centerY / window.innerHeight },
                colors: colors
            });

            if (Date.now() < end) {
                requestAnimationFrame(frame);
            }
        };

        frame();
    };

    return (
        <main class="sweep-thnku">
            {[...Array(44).keys()].map((_, index) => (
                <div key={index} className={`confetti-${index}`}></div>
            ))}

            <section class="thnk-page">
                <img src="images/sweep-bg2.png" class="sweep-thnku-bg" />
                <div class="thnku-content">
                    <img src="images/logo.png" class="sweep-logo" />
                    <div class="sweep-thnku-text">
                        <h1 class="sweep-welcome-heading">THANK YOU</h1>
                        <p class="sweep-welcome-para1">Your reward <br /> will be credited to your account soon. </p>
                    </div>
                </div>

            </section>
        </main>
    )
}

export default Thankyou