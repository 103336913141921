import React, { useContext } from "react";
import { Routes, Route, Navigate, Outlet, useLocation } from "react-router-dom";
import Header from "./components/Shared/Header";
import { UserContext } from "./context/UserContextProvider";
import Login from "./pages/login";
import Chat from "./pages/chat";
import Chatv2 from "./pages/chatv2";
import UpiDetails from "./pages/UpiDetails.js";
import Thankyou from "./pages/Thankyou.js"
import FarmerEntryPoint from "./pages/farmer/FarmerEntryPoint.js"
import EarnCashback from "./pages/farmer/EarnCashback.js"
import Scan from "./pages/farmer/Scan.js"
import Awesome from "./pages/farmer/Awesome.js"
import Congratulation from "./pages/farmer/Congratulation.js"

const RequireAuth = () => {
  const { token } = useContext(UserContext);
  if (!token) {
    return <Navigate to={`/login`} />;
  }
  return <Outlet />;
};

const AppRouter = () => {
  // const location = useLocation();
  // const showHeader = !location.pathname.startsWith("/upiDetails");
  return (
    <>
    {/* {showHeader && <Header />} */}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/login/:id" element={<Login />} />
        <Route path="/" element={<RequireAuth />}>
          <Route path="/" element={<Chatv2 />}></Route>
        </Route>
        {/* routes for upi related scenarios for farmers*/}
        <Route path="/farmer" element={<FarmerEntryPoint />}></Route>
        <Route path="/earnCashback/:id" element={<EarnCashback />}></Route>
        <Route path="/scan/:id" element={<Scan />}></Route>
        <Route path="/awesome/:id" element={<Awesome />}></Route>
        <Route path="/upiDetails/:id" element={<UpiDetails />}></Route>
        <Route path="/congratulation" element={<Congratulation />}></Route>
        <Route path="/thankyou" element={<Thankyou />}></Route>
      </Routes>
    </>
  );
};

export default AppRouter;
