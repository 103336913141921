import { faCommentsDollar } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import "../upiDetails.css"
import APIServicenew from "../utils/APIGeneralService";
import { ToastContainer, toast } from 'react-toastify';
const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL)

const UpiDetails = () => {
    const { id } = useParams();
    const { register, handleSubmit, formState: { errors }, } = useForm();
    const naviagte = useNavigate();
    const [error, setError] = useState("")

    const handleUpiDataSubmit = async (data) => {
        console.log("data with user id  ", { userId: id, ...data })
        try {
            const res = await APIServices.post(`farmer/addDetails/${id}`, data);
            if (res.status === 200) {
                if (res.data && res.data.status) {
                    naviagte("/thankyou")
                } else {
                    console.log("res?.message", res);
                    // setError(res?.data?.message || 'Something went wrong, please try after sometime.')
                    toast.error(res?.data?.message || 'Something went wrong, please try after sometime.', {
                        className: 'dark-toast'
                      });
                }
            }
        } catch (error) {
            console.log("error", error);
            // setError(error?.message || 'Something went wrong, please try after sometime.')
            toast.error(error?.message || 'Something went wrong, please try after sometime.');
        }

    }

    return (
        <>
          <ToastContainer  theme="colored" />
      
        <main className="sweep-thnku">
            <section className="thnk-page">
                <img src="/images/sweep-bg.png" className="sweep-thnku-bg" />
                <div className="welcome-content">
                    <img src="/images/logo.png" className="sweep-logo" />
                    <div className="sweep-thnku-text">
                        <h1 className="sweep-welcome-heading">Welcome</h1>
                        <p className="sweep-welcome-para1">to the SWEEP POWER Reward Program</p>
                    </div>
                    <form className="sweep-input-wrapper" onSubmit={handleSubmit(handleUpiDataSubmit)}>
                        <div className="sweep-gpay">
                            <p className="sweep-upi-para">Please enter <br />  your UPI ID or GPAY no. below</p>
                            <div className="sweep-input-wrapper">
                                <input type="text"
                                    {...register("upiValue", {
                                        required: true
                                    })}
                                    className="form-control" />
                                <select className="form-select" aria-label="Default select example"
                                    {...register("upiType", {
                                        required: true
                                    })}
                                >
                                    <option value="">- Select</option>
                                    <option value="upi_id">UPI ID</option>
                                    <option value="gpay_number">GPay No.</option>
                                </select>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    {errors.upiValue?.type === "required" && (
                                        <p className="text-danger">Value is required</p>
                                    )}

                                </div>
                                <div class="col-4">
                                    {errors.upiType?.type === "required" && (
                                        <p className="text-danger">Type is required</p>
                                    )}

                                </div>
                            </div>

                            <button 
                            // style={{ color: "white", backgroundColor: "#19875F" }}
                             className="btn btn-success sweep-submit">SUBMIT</button>
                            {/* {error && <p className="text-danger">
                                {error}
                            </p>} */}
                        </div>
                    </form>
                </div>
            </section>
        </main>
        </>
    )
}

export default UpiDetails;