import React, {useContext} from "react";
import {NavLink} from "react-router-dom";
import {Navbar, Nav, NavDropdown} from "react-bootstrap";
import {PersonCircle} from "react-bootstrap-icons";
import {useNavigate} from "react-router-dom";
import {UserContext} from "../../context/UserContextProvider";
import {setCookies} from "../../utils/Helper";

const Header = () => {
  const navigate = useNavigate();
  const {token, refreshToken} = useContext(UserContext);

  const handleLogout = e => {
    e.preventDefault();
    refreshToken(null);
    setCookies("accessTokenleads", null);
    setCookies("accessToken", null);
    setCookies("astroUserId", null);
    
    navigate("/");
  };

  const renderMenuItems = () => {

    return (
      <>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="content_nav">
          <h2 className="main-logo">Sweep Power</h2>
          <Nav className="ms-auto">
            {/* <NavLink to="/" className="nav-link">
              Chat
            </NavLink> */}

            <NavLink to="/" className="nav-link">
              Chat
            </NavLink>
            <NavDropdown
              id="responsive-navbar-nav"
              title={<PersonCircle className="me-1 " size={15} />}
              className="main-navbar"
            >
              <NavDropdown.Item onClick={(e) => handleLogout(e)}>
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </>
    );
  };

  return (
    <div>
      <div className="row p-0 m-0">
        <div className="col-md-12 p-0 m-0">
          <Navbar  className="header">
            {token ? (
              renderMenuItems()
            ) : (
              <Nav className="ms-auto" style={{height: "55px"}}>
                {/* <NavLink to="/login" className="nav-link" >
                  ''
                </NavLink> */}
              </Nav>
            )}
          </Navbar>
        </div>
      </div>
    </div>
  );
};

export default Header;
