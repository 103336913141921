import React, { useEffect, useState } from "react";
import APIServicenew from "../utils/APIGeneralService";
import { getCookies } from "../utils/Helper";

const UserContext = React.createContext();

const UserContextProvider = (props) => {
  const [token, setToken] = useState(getCookies("accessToken"));
  const [user, setUser] = useState(null);

  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL)


  useEffect(() => {
    (async () => {
      const userId = getCookies("astroUserId")
      // console.log('userIduserIduserIduserIduserId', userId)
      if (userId && token && token != "null") {
        // const userRes = await APIServices.get(`admin/adminDetail/${userId}`, {
        //   'x-access-token': token,
        // });
        // if (userRes && userRes.status === 200) {
          // let userInfo = userRes?.data?.data?.[0]
          let userInfo = {};
          userInfo._id = userId;
          setUser(userInfo);
        // }
      } else if (token === "null") {
        refreshToken(null);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  /* 
    If user logoff or login, update token from child component
  */
  const refreshToken = (newToken) => {
    setToken(newToken);
  };

  return (
    <UserContext.Provider value={{ user, token, refreshToken }}>
      {props.children}
    </UserContext.Provider>
  );
};
export { UserContextProvider, UserContext };
