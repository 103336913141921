import React, { useRef, useEffect, useState } from 'react'
import jsQR from 'jsqr';
import APIServicenew from "../../utils/APIGeneralService";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useParams } from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas';
const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL)

function Scan() {
  const videoRef = useRef(null);
  const [result, setResult] = useState('');
  const [permissionRequested, setPermissionRequested] = useState(false);
  // const [showPopup, setShowPopup] = useState(true);
  const [uidNum , setUidNum]= useState("")
  const [show, setShow] = useState(false);
  const { id } = useParams();
  const naviagte = useNavigate();
  console.log("id",)

  useEffect(() => {
    const video = videoRef.current;
    let stream = null;

    const startCamera = async () => {
      try {
        if (!permissionRequested) {
          const permission = await navigator.permissions.query({ name: 'camera' });
          if (permission.state === 'denied') {
            console.error('Camera access denied by user');
            return;
          }
          setPermissionRequested(true);
        }

        stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
        video.srcObject = stream;
        video.play();
        video.onloadedmetadata = () => {
          scanQRCode();
        };
      } catch (err) {
        console.error('Error accessing camera:', err);
      }
    };

    const scanQRCode = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      const width = video.videoWidth;
      const height = video.videoHeight;

      canvas.width = width;
      canvas.height = height;

      context.drawImage(video, 0, 0, width, height);

      const imageData = context.getImageData(0, 0, width, height);
      const code = jsQR(imageData.data, width, height, {
        inversionAttempts: 'dontInvert',
      });

      if (code) {
        setResult(code.data);
      }

      requestAnimationFrame(scanQRCode);
    };

    const timeoutId = setTimeout(startCamera, 1000); 

    return () => {
      clearTimeout(timeoutId);
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
    };
  }, []);
  const handleSubmit= async ()=>{
    console.log("handle submit called result",{result,uidNum})
    let  data = result ? result : uidNum ? uidNum : null
    
    console.log("data",data)
    if (data) {
      console.log("data with user id  ", { data})
      try {
        console.log("id",id)
        const res = await APIServices.post(`farmer/validateScanData/${id}`, { data });
        console.log("res",res)
        if (res.status === 200) {
          if (res.data && res.data.status) {

            // naviagte("/awesome")
            setShow(true);
            setTimeout(() => {
              setShow(false); 
              naviagte(`/awesome/${id}`)
            }, 3000);
          } else {
            console.log("res?.message", res);
            toast.error(res?.data?.message || 'Something went wrong, please try after sometime.', {
              className: 'dark-toast'
            });
          }
        }
      } catch (error) {
        console.log("error in catch handleSubmit ", error);
        // setError(error?.message || 'Something went wrong, please try after sometime.')
        toast.error(error?.message || 'Something went wrong, please try after sometime.');
      }
    }


  }
  return (
    <>
    <main class="sweep-thnku farmer_landing">
          <ToastContainer  theme="colored" />
    <section class="thnk-page mt-5">
    <div>
      <video ref={videoRef} style={{ width: '90%' }} />
      <p>{result}</p>
      <p class="scan-upi-para">OR</p>
    <input type="text" value={uidNum} onChange={(e)=>setUidNum(e.target.value)} placeholder="Enter UID no" />
    </div>
    <button className="btn btn-success sweep-submit"  onClick={handleSubmit}>SUBMIT</button>
   
    </section>
    <Offcanvas show={show}  placement="bottom">
        {/* <Offcanvas.Body className="scan_success"> */}
        <div className=" offcanvas-bottom scan_success" id="scan_success">
          <div className="offcanvas-body p-0">
            <img src="../images/canvas_bg.png" className="img-fluid canvas_bg" style={{width:"100%"}} />

            <div className="cs_success_inner">
              <img src="../images/right_icon.png" className="img-fluid right_icon" />
              <h4 className="middle_heading">SCAN SUCCESSFUL</h4>
            </div>
          </div>
        </div>
        {/* </Offcanvas.Body> */}
      </Offcanvas>

</main>
          {/* Popup
          {showPopup && (
        <div className="offcanvas offcanvas-bottom scan_success" id="scan_success">
          <div className="offcanvas-body p-0">
            <img src="images/canvas_bg.png" className="img-fluid canvas_bg" />

            <div className="cs_success_inner">
              <img src="images/right_icon.png" className="img-fluid right_icon" />
              <h4 className="middle_heading">SCAN SUCCESSFUL</h4>
            </div>
          </div>
        </div>
      )} */}
</>
  )
}

export default Scan