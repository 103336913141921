
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import 'react-toastify/dist/ReactToastify.css';
import { UserContextProvider } from "./context/UserContextProvider";
import AppRouter from "./AppRouter";

const App = () => {

  return (
    <div className="App">
      <UserContextProvider>
        <AppRouter />
      </UserContextProvider>
    </div>
  );
};

export default App;
