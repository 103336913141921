import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Spinner } from "react-bootstrap";
import { useSearchParams, useNavigate ,useParams } from "react-router-dom";
import { EyeFill, EyeSlashFill } from "react-bootstrap-icons";
import { UserContext } from "../context/UserContextProvider";
import { setCookies } from "../utils/Helper";
import APIServicenew from "../utils/APIGeneralService";
import Header from "../components/Shared/Header";


const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [passwordShown, setPasswordShown] = useState(false);
  const { refreshToken } = useContext(UserContext);
  const[loginerror,setLoginerror]=useState()
  const [params] = useSearchParams();
  const naviagte = useNavigate();
  const[loader,setLoader]=useState(false)
  const { id } = useParams();
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL)

  // let redirectTo = "/";
  // let redirectInParams = params && params.get("redirect");
  // if (redirectInParams) {
  //   redirectTo = redirectInParams;
  // }

  
    const handleLogin = async (data) => {
      setLoader(true)
    const res = await APIServices.get(`admin/login/${id}`);
    if (res.status === 200) {
      if (res.data && res.data.status) {
        let accessToken = res.data?.data?.[0]?.accessToken;
        setCookies("accessToken", accessToken);
        setCookies("astroUserId", res.data?.data?.[0]?._id);
        refreshToken(accessToken);
        setLoader(false)
          naviagte("/")     
      }
    }
    setLoader(false)
  };
  useEffect(() => {
    if(id){
      handleLogin()
    }
  }, [])
  

  return (<>
  <Header />
    

  <section
      className="as_login_area"
    >

      <img src="./images/login-shape.png" className="login-shape" />
      <div className="container">
      <div className="login_inner">
      <div className="as_login_fgtPwd" style={{color: "black",textAlign:"center"}}>
      <h2>{loader ? "Loading ....": "Unauthorized Access"}</h2>

    </div>
          <div className="login_form">
            {/* <form onSubmit={handleSubmit(handleLogin)}>
              <div className="form-group">
                <input
                  type="text"
                  {...register("email", {
                    required: true,
                    pattern:
                      /^[a-zA-Z0-9\s._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z\s]{2,4}$/,
                  })}
                  className="form-control "
                  placeholder="Enter Email ID Here"
                />
                {errors.email?.type === "required" && (
                  <p className="text-danger">Email is required</p>
                )}
                {errors.email?.type === "pattern" && (
                  <p className="text-danger">Invalid Email</p>
                )}
              </div>
              <div className="form-group position-relative">
                <input
                  {...register("password", {
                    required: true,
                    pattern:
                      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                    maxLength: 50,
                    minLength: 8,
                  })}
                  className="form-control"
                  type={passwordShown ? "text" : "password"}
                  placeholder="Enter Password Here"
                />
                <span onClick={togglePasswordVisiblity} className="shownpwd">
                  {passwordShown ? <EyeFill /> : <EyeSlashFill />}
                </span>
                {errors.password?.type === "required" && (
                  <p className="text-danger">You must specify a password</p>
                )}
                {errors.password?.type === "pattern" && (
                  <p className="text-danger">
                    Password must have one small letter ,capital letter, digit
                    and special character
                  </p>
                )}
                {errors.password?.type === "maxLength" && (
                  <p className="text-danger">
                    Password must have maximum length of 50 characters
                  </p>
                )}
                {errors.password?.type === "minLength" && (
                  <p className="text-danger">
                    Password must have at least 8 characters
                  </p>
                )}
                
                  <p className="text-danger">
                    {loginerror}
                  </p>
               
              </div>

              <div className="text-center mt-3">
              {loader?<Spinner/>:<button
                type="submit"
                className="as_btn"
              >
                Login
              </button>}
                
              </div>
            </form> */}
          </div>
        </div>
      </div>
    </section>
   
    </>
  );
};

export default Login;
