import React from 'react'
import "./farmer.css"

function Congratulation() {
  return (
    <main class="sweep-thnku farmer_landing farmer_congratulation">
    <section class="thnk-page">
        <img src="../images/farmer_bg3.png" class="sweep-thnku-bg"/>
        <div class="welcome-content">
         <img src="../images/congratulation.png" class="img-fluid sweep-logo" alt=""/>
            <div class="sweep-gpay">
                <p class="sweep-upi-para">You got ₹ 15 cashback for scanning
               <br/>  3 L SWEEP POWER</p>
              
               
            </div>
            

        </div>
        <img src="../images/casback.png" class="img-fluid cashback_img" alt=""/>
    </section>
</main>
  )
}

export default Congratulation